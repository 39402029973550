import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/global/Layout";
import SEO from "../components/global/SEO";

import { renderCaseStudyPracticesTwo } from "../js/util";
import { renderBlocks } from "../js/pageBlockRender";
import { searchFieldIndex } from "../js/util";

import "../components/sass/blocks.scss";
import "../components/sass/templates/_case-study.scss";

import PlaceholderSvg from "../components/helpers/placeholder";

const CaseStudyTemplate = ({ data }) => {
  /**
   * Object representing the Case Study Hero.
   *
   * @TODO: to really ensure that the hero comes first and is rendered first,
   * refactor this out of the repeater and into an ACF that always appears on
   * all case-study post-types
   */
  const [caseStudyHeroBlock] = data.currentCaseStudy.acf.case_study_blocks[
    searchFieldIndex(
      data.currentCaseStudy.acf.case_study_blocks,
      "case_study_hero"
    )
  ].block_type;

  // Could be null.
  const heroImage = caseStudyHeroBlock.csh_image;
  const { categories } = data.currentCaseStudy;
  const isVideo = caseStudyHeroBlock.csh_is_video;
  const addGradient = caseStudyHeroBlock.csh_add_gradient;
  const textColor = caseStudyHeroBlock.csh_text_color;
  
  return (
    <Layout>
      <SEO yoast={data.currentCaseStudy.yoast} />
      <div className="case-study">
        <div className={`case-study-hero case-study-hero--image ${textColor ? 'light-text' : 'dark-text'}`}>
          {isVideo ?
            <div className={"case-study-hero__video"}>
              <video playsInline muted autoPlay loop>
                <source src={caseStudyHeroBlock.csh_video} type="video/mp4"></source>
              </video>
            </div>
            :
            (
              !!heroImage ? (
                <div className="case-study-hero-img">
                  <Img
                    alt={heroImage.alt_text || ""}
                    fluid={heroImage.localFile.childImageSharp.fluid}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  />
                </div>
              ) : (
                  <PlaceholderSvg
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  />
                )
            )
          }
          {addGradient ? 
            <div className="case-study-hero__gradient"></div>
          : 
            ""
          }
          <div className="case-study-hero__content l-content l-content--fixed">
            <h1 className="case-study-hero__title t-h1">
              {caseStudyHeroBlock.csh_title}
            </h1>
          </div>
        </div>
        <div className="case-study__content">
          <div className="case-study__intro l-content l-content--fixed">
            <h2 className="case-study__title t-h3 l-vspace">
              {data.currentCaseStudy.post_title}
            </h2>
            <p className="case-study__subtitle mobile t-h2">
              {caseStudyHeroBlock.csh_title}
            </p>
            <div className="case-study__situation l-vspace">
              <h3 className="case-study-situation__title t-h6">
                The situation
              </h3>
              <p
                className="case-study-situation__text"
                dangerouslySetInnerHTML={{
                  __html:
                    data.currentCaseStudy.acf.case_study_blocks[
                      searchFieldIndex(
                        data.currentCaseStudy.acf.case_study_blocks,
                        "situation"
                      )
                    ].block_type[0].situation
                }}
              ></p>
            </div>
            {!!categories && (
              <div className="case-study__practices l-vspace">
                <div className="case-study__practices-title t-h6">
                  Services
                </div>
                <div className="case-study-practices__content">
                  {renderCaseStudyPracticesTwo(categories)}
                </div>
              </div>
            )}
          </div>
          <div className="case-study__blocks">
            {renderBlocks(
              data.currentCaseStudy.acf !== null
                ? data.currentCaseStudy.acf.case_study_blocks
                : ""
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CaseStudyTemplate;

export const caseStudyQuery = graphql`
  query($id: String!) {
    currentCaseStudy: wordpressCaseStudyEndpointCaseStudies(id: { eq: $id }) {
      post_title
      yoast {
        focuskw
        title
        metadesc
        opengraph_title
        opengraph_description
        opengraph_updated_time
        twitter_title
        twitter_description
        linkdex
        metakeywords
        meta_robots_noindex
        meta_robots_nofollow
        meta_robots_adv
        canonical
        redirect
        opengraph_url
        opengraph_site_name
        opengraph_type
      }
      categories {
        name
      }
      acf {
        case_study_blocks {
          block_type {
            acf_fc_layout
            csh_text_color
            csh_is_video
            csh_add_gradient
            csh_video
            csh_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            csh_title
            
            statc_num_columns
            statc_background_colors {
              color
            }
            statc_text_color
            statc_stats {
              number
              text
            }

            dl_style

            fwtt_eyebrow
            fwtt_alignment
            fwtt_title
            fwtt_text

            situation

            media_item {
              media_item_size 
              media_item_image_or_video
              media_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              media_video_url
              media_video_autoplay_or_click
            }

            sc_intro
            sc_text
            sc_text_color
            sc_background_color

            q_intro
            q_quote
            q_quotee

            tct_small_title
            tct_text
            tct_has_circle
            tct_circle_color 
            
            rcs_intro_title
            rcs_related_cases {
              case_study {
                post_title
              }
              image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/* tcmts_bg_color
tcmts_text
tcmts_media {
  localFile {
    childImageSharp {
      fluid(quality: 100, maxWidth: 4000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}, */
